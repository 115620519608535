import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import indexStyles from '../../index.module.css'
import React from 'react'

export const PbColumns: GridColDef[] = [
    // {
    //     field: 'special', headerName: ' ',
    //     width: 50, editable: false,
    //     disableColumnMenu: true,
    //     sortable: false,
    //     valueFormatter: (params) => params.value === 1 ? '🆕' : ''
    // },
    { field: 'title', headerName: 'Título', width: 250, editable: false, disableColumnMenu: false, flex: 1 },
    {
        field: 'view', headerName: 'Ação', headerAlign: 'center',
        width: 80, editable: false,
        disableColumnMenu: true, sortable: false,
        align: 'center',
        renderCell: (params: GridRenderCellParams<string>) => (
            <div className={indexStyles.tooltip}>
                {params.value}                
                <span className={indexStyles.tooltiptext}>
                    {params.row.serverPath.endsWith('pdf') && !navigator.pdfViewerEnabled ? 'Download' : 'Visualizar'}
                </span>
            </div>
        )
    },
    {
        field: 'repertoire', headerName: 'Repertório',
        width: 120, editable: false,
        disableColumnMenu: true, sortable: false, headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams<string>) => (
            <div className={indexStyles.tooltip}>
                {params.value}                
                <span className={indexStyles.tooltiptext}>
                    {'Adicionar'}
                </span>
            </div>
        )
    }
]