import React, { useEffect, useState } from 'react'
import style from './sheet-item.module.css';
import mainStyles from '../../index.module.css'
import { FileResponse } from '../interfaces/FileResponse';


export interface IMovementButton {
    buttonType: MovementType,
    onClick: (file: FileResponse) => Promise<any>,
    current: FileResponse
}

export enum MovementType {
    Up,
    Down,
    First,
    Last,
    Remove
}

export default function MovementButton(props: IMovementButton) {
    const [emoji, setEmoji] = useState('')
    const [tip, setTip] = useState('')

    useEffect(() => {
        switch (props.buttonType) {
            case MovementType.Up:
                setEmoji('⬅️');
                setTip('Mover partitura para frente')
                break;
            case MovementType.First:
                setEmoji('⏮️');
                setTip('Mover partitura para primeira posição')
                break;
            case MovementType.Down:
                setEmoji('➡️')
                setTip('Mover partitura para trás')
                break;
            case MovementType.Last:
                setEmoji('⏭️');
                setTip('Mover partitura para última posição')
                break;
            case MovementType.Remove:
                setEmoji('❌')
                setTip('Excluir do repertório')
                break;
            default:
                break;
        }
    })

    return <div className={style.navigationButton} onClick={async () => await props.onClick(props.current)}>
        <div className={mainStyles.tooltip}>
            <span style={{ fontSize: '36px' }}>{emoji}</span>
            <span className={mainStyles.tooltiptext}>
                {tip}
            </span>
        </div>
    </div>
}