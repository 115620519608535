import styles from '../../index.module.css'
import React, { useState } from 'react'
import PbButton from '../button/button.component';

interface SearchProps {
  onSearchButtonClick: (value: string) => void
}

export default function Search(props: SearchProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const onKeyDown = async (e: any) => {
    if (e.keyCode == 13)
      await props.onSearchButtonClick(searchTerm);
  }

  return <>
    <div className={styles.lines}>
      <input
        name="txtsearch"
        type="text"
        className={styles.urltext}
        placeholder="Digite sua pesquisa..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyUp={onKeyDown}
      />
      <PbButton label='🔎' onClick={() => props.onSearchButtonClick(searchTerm)} />
    </div>
  </>
}