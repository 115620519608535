import { HubConnection } from '@microsoft/signalr';
import React, { useState, useEffect } from 'react'
import { LoginService } from '../../services/login.service';
import stylesLogin from './login.module.css';
import { browserName } from 'react-device-detect';
import { AuthContext } from '../../providers/auth.provider';
import Header from '../../components/header/header.component';
import styles from '../../index.module.css'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SignalRHelper from '../../components/infra/signalrHelper.component';

enum LoginPhase {
    BackendIinitializing,
    BackendReady,
    AwaitingTelegram
}

enum SignalRMethods {
    successLogin = 'successLogin',
    failedLogin = 'failedLogin'
}

export default function Login() {
    const [login, setLogin] = useState('');
    const [loginMessage, setLoginMessage] = useState('Inicializando... (Caso não responda em 1 minuto, atualize a página)');
    const [loginPhase, setLoginPhase] = useState(LoginPhase.BackendIinitializing);
    const [loginConnection, setLoginConnection] = useState<HubConnection>();

    const auth = React.useContext(AuthContext);
    const navigate = useNavigate();
    const service = new LoginService();

    useEffect(() => {doHealthCheck();}, [loginPhase]);

    useEffect(() => {
        if (loginConnection) {
            loginConnection.serverTimeoutInMilliseconds = 120 * 1000;

            loginConnection.start()
                .then(() => {
                    loginConnection.on(SignalRMethods.successLogin, async data => {
                        if (data) {
                            loginConnection.stop()
                                .then(() => {
                                    toast('Login realizado!');
                                    auth.onLogin(data.token);
                                    navigate('/');
                                })
                        }
                        else throw new Error('login failed.')
                    });

                    loginConnection.on(SignalRMethods.failedLogin, () => {
                        loginConnection.stop();
                        throw new Error('login failed.');
                    });
                })
                .catch(e => {
                    console.log('Connection failed: ', e);
                    setLoginPhase(LoginPhase.BackendIinitializing);
                    toast.error('Falha ao realizar o login!')
                });

            loginConnection.onclose(() => setLoginPhase(LoginPhase.BackendIinitializing));
        }
    }, [loginConnection])

    const doHealthCheck = async () => {
        if (loginPhase !== LoginPhase.BackendIinitializing) return;

        const { data } = await service.healthCheck();
        if (data === 'Healthy') {
            await setLoginPhase(LoginPhase.BackendReady);
        }
    }

    const doLogin = async (e: any) => {
        if (!login) {
            toast.warn('Informe o login');
            return;
        }

        const { data } = await service.doLogin({
            login: login
        });

        if (data) {
            //create connection
            await setLoginPhase(LoginPhase.AwaitingTelegram);

            await setLoginConnection(SignalRHelper.NewConnection(`loginHub?login=${login}&browser=${browserName}`));
            await setLoginMessage('Aprove a entrada no Telegram...');
            
            localStorage.setItem('login', login);
        }
        else {
            toast.error('Login inválido!');
        }
    }

    return <div className={styles.main}>
        <Header />
        <div className={stylesLogin.loginline}>
            <h1>Login</h1>
            <input
                name="txtsearch"
                type="text"
                className={stylesLogin.logintext}
                placeholder="Digite seu usuário..."
                value={login}
                onChange={(e) => setLogin(e.target.value)}
            />

            {loginPhase === LoginPhase.BackendReady && <div
                className={stylesLogin.button}
                style={{
                    marginTop: '10px',
                }}
                onClick={doLogin}
            >
                Logar com Telegram
            </div>}
            {(loginPhase === LoginPhase.AwaitingTelegram || loginPhase === LoginPhase.BackendIinitializing) && <h4>{loginMessage}</h4>}
        </div>
    </div>
}