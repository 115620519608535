import React, { useState, useEffect } from 'react'
import { unauthenticate } from '../../redux-ts';
import { useAppDispatch } from '../../redux-ts/hooks';
import { LoginService } from '../../services/login.service';
import styles from './header.module.css'

export default function Header() {
    const [logoutInfoVisible, setLogoutInfoVisible] = useState(true);
    const [loginMenuVisible, setLoginMenuVisible] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setLoginMenuVisible(localStorage.getItem('token') !== null)
    }, 
    [localStorage])


    const service = new LoginService();

    const onLogoutClick = async () => {
        await setLogoutInfoVisible(false);

        const { data } = await service.logout({
            login: String(localStorage.getItem('login')),
            token: String(localStorage.getItem('token'))
        });

        if (data) {
            dispatch(unauthenticate());
            
            localStorage.removeItem('token');
            localStorage.removeItem('login');

            window.location.reload();
        }
    }

    return <>
        {loginMenuVisible && <div className={styles.links}>
            <a href='/' className={styles.item}>
                {'Buscar'}
            </a>
            <a href='/collection-list' className={styles.item}>
                {'Lista do Acervo'}
            </a>
            <a href='/my-repertoire' className={styles.item}>
                {'Meu repertório'}
            </a>
            {logoutInfoVisible && <a href='#' onClick={onLogoutClick} className={styles.item}>
                {'Sair'}
            </a>}
        </div>}
        {!loginMenuVisible && <div className={styles.links}>
            <a href='/be-vip'className={styles.item}>{'Seja VIP!'}</a>
            </div>}
        <Title />
    </>
}

export const Title = () => <h2 className={styles.title}>
    {'🤖 Partitura BOT VIP 🤖'}
</h2>