export interface FileResponse {
    address: string,
    extension: string,
    serverPath: string,
    source: FileSource
}

export enum FileSource {
    Crawler,
    Dropbox,
    Instagram,
    Generated
}