import React from 'react'

export default class FileHelper {
    public static TryDownload (name: string, blob: Blob): void {
        if (name.endsWith('.pdf')) {
            const fileURL = window.URL.createObjectURL(blob!);

            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = name;

            document.body.appendChild(alink);
            alink.click();
            alink.parentNode!.removeChild(alink);
        }
    }
}