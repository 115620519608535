import React, { useState } from 'react'
import Header from '../../components/header/header.component';
import Search from '../../components/search/search.component';
import styles from '../../index.module.css'
import { FileResponse } from '../../components/interfaces/FileResponse';
import { SearchService } from '../../services/search.service';
import SheetItem, { AddButtonPosition } from '../../components/search/sheet-item.component';
import Loader from '../../components/loader/loader.component';
import PbButton from '../../components/button/button.component';
import FileHelper from '../../helpers/filehelper.helper';
import { StringHelper } from '../../components/infra/stringHelper.component';
import { toast } from 'react-toastify';
import RepertoireService from '../../services/repertoire.service';

export default function MainSearch() {
  const [foundItems, setFoundItems] = useState<FileResponse[]>();
  const [searching, setSearching] = useState(false);
  const [currentTerm, setCurrentTerm] = useState('');

  const searchService = new SearchService();
  const repertoireService = new RepertoireService();

  const performSearch = async (term: string) => {
    await setSearching(true);
    await setFoundItems(undefined)
    await setCurrentTerm(term);

    try {
      if (!term) {
        return;
      }

      const { data } = await searchService.search(term);

      if (data && data.length > 0) {
        await setFoundItems(data);
      }

    } catch (error) {
      toast.warning('Não foi possivel realizar a pesquisa!');
    }
    finally {
      await setSearching(false);
    }

  }

  const onDownloadPdf = async () => {
    const { data } = await searchService.generatePdf(currentTerm);

    if (data) {
      const base64ByteArray = StringHelper.base64ToArrayBuffer(data);

      FileHelper.TryDownload(`${currentTerm}.pdf`, new Blob([base64ByteArray], { type: "application/pdf" }))
    }
  }

  const onAddButtonClick = async (file: FileResponse) => {
    try {
      await repertoireService.add(file);

      toast.success(`Partitura adicionada ao seu repertório!`);

    } catch {
      toast.error(`Não foi possivel adicionar a partitura ao repertório.`)
    }
  }

  return <div className={styles.main}>
    <Header />
    <Search
      onSearchButtonClick={performSearch}
    />
    {searching && <Loader />}
    {!foundItems && !searching && <label style={{ textAlign: 'center', marginTop: '10px' }}>Sem items a exibir</label>}
    {foundItems && <div className={styles.pdfButton}><PbButton label='Baixar PDF da pesquisa' onClick={onDownloadPdf} /></div>}
    {foundItems && !searching && <div className={styles.imageItem}>
      {foundItems.map(
        (value, index) =>
          <SheetItem
            key={index}
            item={value}
            addButton={{ position: AddButtonPosition.Low, onClick: onAddButtonClick }} />)}
    </div>}
  </div>
}