import React from 'react'
import styles from './footer.module.css'

export default function Footer(){
    return <footer className={styles.footer}>
    <a
      href="http://t.me/partiturabot"
      target="_blank"
      rel="noopener noreferrer"
    >
      Pesquise no Telegram!
    </a>
  </footer>
}