import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Header from '../../components/header/header.component'
import { FileResponse } from '../../components/interfaces/FileResponse';
import Loader from '../../components/loader/loader.component';
import SheetItem from '../../components/search/sheet-item.component';
import styles from '../../index.module.css'
import RepertoireService from '../../services/repertoire.service';
import "react-slideshow-image/dist/styles.css";
import { Slide } from 'react-slideshow-image';
import FileHelper from '../../helpers/filehelper.helper';
import { StringHelper } from '../../components/infra/stringHelper.component';

export default function MyRepertoire() {
    const [repertoire, setRepertoire] = useState<FileResponse[]>();
    const [searching, setSearching] = useState(false);

    const service = new RepertoireService();

    useEffect(() => {
        loadRepertoire();
    }, [])

    const loadRepertoire = async (force: boolean = false) => {
        if (repertoire && !force) return;

        try {
            await setSearching(true);

            const { data } = await service.get();

            if (data && data.sheets.length > 0) {
                await setRepertoire(data.sheets);
            }
            else if (force)
                await setRepertoire(undefined);
        }
        catch {
            toast.warn('Não foi possível carregar o repertório!');
        }
        finally {
            await setSearching(false);
        }
    }

    const onUpButtonClick = async (file: FileResponse) => {
        await onMovementButtonClick(file, f => service.up(f));
    }

    const onFirstButtonClick = async (file: FileResponse) => {
        await onMovementButtonClick(file, f => service.first(f));
    }

    const onDownButtonClick = async (file: FileResponse) => {
        await onMovementButtonClick(file, f => service.down(f));
    }

    const onLastButtonClick = async (file: FileResponse) => {
        await onMovementButtonClick(file, f => service.last(f));
    }

    const onRemoveButtonClick = async (file: FileResponse) => {
        await onMovementButtonClick(file, f => service.remove(f));
    }

    const onMovementButtonClick = async (file: FileResponse, action: (file: FileResponse) => Promise<any>) => {
        try {
            await action(file);
            await loadRepertoire(true);

            toast.success(`Partitura movimentada com sucesso.`);
        } catch {
            toast.error(`Não foi possivel movimentar a partitura.`)
        }
    }

    const onCleanButtonClick = async () => {
        try {
            await service.clear();
            await loadRepertoire(true);

            toast.success(`Repertório limpo com sucesso.`);
        } catch {
            toast.error(`Não foi possivel limpar o repertório.`)
        }
    }

    const onDownloadRepertoireClick = async () => {
        try {
            await service.download();

            const { data } = await service.download();

            if (data) {
                const base64ByteArray = StringHelper.base64ToArrayBuffer(data);

                FileHelper.TryDownload(`Repertorio.pdf`, new Blob([base64ByteArray], { type: "application/pdf" }))
                toast.success(`Repertório baixado com sucesso.`);
            }            
        } catch {
            toast.error(`Não foi possivel baixar o repertório.`)
        }
    }

    return <div className={styles.main}>
        <Header />
        <h2>Meu Repertório</h2>
        {repertoire && !searching && <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginBottom: '5px'
        }}>
            <div className={styles.button} onClick={onCleanButtonClick}>Limpar repertório 🧹</div>
            <div className={styles.button} onClick={onDownloadRepertoireClick}>Baixar como PDF 🔽</div>
        </div>}

        {searching && <Loader />}
        {repertoire && !searching && <Slide
            autoplay={false}
            transitionDuration={500}
        >
            {repertoire.map((value, index) => <SheetItem
                key={index}
                item={value}
                upButton={{ visible: index !== 0, onClick: onUpButtonClick }}
                firstButton={{ visible: index !== 0, onClick: onFirstButtonClick }}
                downButton={{ visible: index !== repertoire.length - 1, onClick: onDownButtonClick }}
                lastButton={{ visible: index !== repertoire.length - 1, onClick: onLastButtonClick }}
                removeButton={{ visible: true, onClick: onRemoveButtonClick }}
            />)} </Slide>}
        {!repertoire && !searching && <div><p>Sem dados a exibir</p></div>}

        {/* list with
    - image, pdf
    - button to remove

    * option to full screen
    * navigation at the page bottom
    (MUST) save locally the links
    (?) save images offline
    */}
    </div>
}