import React from 'react'
import styles from '../../index.module.css'

export interface PbButtonProps{
    onClick: () => any,
    label: string
}

export default function PbButton(props: PbButtonProps){
    const onKeyDown = async (e: any) => {
        if (e.keyCode == 13)
          await props.onClick();
      }
    
    return <div className={styles.button}
        onClick={props.onClick}
        onKeyUp={onKeyDown}
    >
    {props.label}
  </div>
}