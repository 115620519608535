import React, { useEffect, useState } from 'react'
import { DataGrid, GridRowsProp, GridColDef, GridOverlay, GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';

export interface PbGridProps {
    rows: GridRowsProp,
    columns: GridColDef[],
    onLineClick: (e: GridCellParams<any, any, any>) => void
}

export default function PbGrid(props: PbGridProps) {
    return <>
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid
                rows={props.rows}
                columns={props.columns!}
                hideFooterSelectedRowCount
                components={{
                    NoRowsOverlay: () => <>
                        <GridOverlay style={{
                            flexDirection: 'column'
                        }}>
                            <div>Sem dados a exibir</div>
                        </GridOverlay>
                    </>
                }}
                onCellClick={props.onLineClick}
            />
        </div>
    </>
}