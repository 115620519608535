import { AxiosResponse } from 'axios';
import { HttpClient } from '../components/infra/httpClient.component';
import { FileResponse } from '../components/interfaces/FileResponse';

export interface Repertoire{
    sheets: FileResponse[]
}

export class RepertoireService {
    private request: HttpClient;

    constructor() {
        this.request = new HttpClient();
    }

    public get(): Promise<AxiosResponse<Repertoire, any>> {
        return this.request.get(`repertoire`)
    }

    public add(data: FileResponse): Promise<AxiosResponse<any, any>> {
        return this.request.patch(`repertoire`, data)
    }

    public up(data: FileResponse): Promise<AxiosResponse<any, any>> {
        return this.request.patch(`repertoire/up`, data);
    }

    public first(data: FileResponse): Promise<AxiosResponse<any, any>> {
        return this.request.patch(`repertoire/first`, data);
    }

    public down(data: FileResponse): Promise<AxiosResponse<any, any>> {
        return this.request.patch(`repertoire/down`, data);
    }

    public last(data: FileResponse): Promise<AxiosResponse<any, any>> {
        return this.request.patch(`repertoire/last`, data);
    }

    public remove(data: FileResponse): Promise<AxiosResponse<any, any>> {
        return this.request.patch(`repertoire/remove`, data);
    }

    public clear(): Promise<AxiosResponse<any, any>> {
        return this.request.post(`repertoire/clear`);
    }

    public download(): Promise<AxiosResponse<any, any>> {
        return this.request.post(`repertoire/download`);
    }
}

export default RepertoireService;