import { HubConnectionBuilder } from '@microsoft/signalr';
import { API_URL } from '../../configs/env';

enum HttpTransportType {
    WebSockets = 1
}

class SignalRHelper {

    public static NewConnection(url: string) {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${API_URL}hubs/${url}`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            }).build();

        return newConnection;
    }
}

export default SignalRHelper;