import React from 'react'
import { Title } from '../../components/header/header.component'
import style from './member.module.css'
import mainstyle from '../../index.module.css'

function MemberHeader(){
    return <>
    {<div className={style.links}>
        <a href='/login' className={style.item}>
            {'Login'}
        </a>
    </div>}
    <Title />
</>
}

export default function BeMember() {
    return <div className={mainstyle.main}>
        <MemberHeader />
        <h2 className={style.title}>Seja um membro VIP!</h2>
        <div className={style.text}>
            <p>Para ter acesso ao conteúdo exclusivo e em constante evolução do Partitura VIP,
            basta fazer um pagamento anual de R$20,00 via PIX.</p>
            <p>Chave: <b>rrnazario@gmail.com</b></p>
            <p>Mais informações? Envie uma mensagem no telegram para nós. <a href='http://t.me/rrnazario' target={'_blank'}>Clique aqui!</a></p>            
        </div>
        <p></p>
    </div>
}