import { Dropbox } from "dropbox";
import { DROPBOX_TOKEN } from "../../configs/env";

export default class PdbDropbox {
    private drop: Dropbox;

    constructor() {
        this.drop  = new Dropbox({ accessToken: DROPBOX_TOKEN });
    }

    public async GetFromServer(path:string):Promise<Blob> {
        const downloadedContent: any = await this.drop.filesDownload({ path: path })

        return downloadedContent.result.fileBlob as Blob;
    }    
}