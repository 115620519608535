import { AxiosResponse } from 'axios';
import { HttpClient } from '../components/infra/httpClient.component';

export class CollectionService {
    private request: HttpClient;

    constructor(){
        this.request = new HttpClient()
    }

    public getAll (): Promise<AxiosResponse<any, any>> {
        return this.request.get(`collection`)
    }
}

export default CollectionService;