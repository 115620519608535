import { AxiosResponse } from 'axios';
import { HttpClient } from '../components/infra/httpClient.component';
import { FileResponse } from '../components/interfaces/FileResponse';

export class SearchService {
    private request: HttpClient;

    constructor(){
        this.request = new HttpClient();
    }

    public search (data:string): Promise<AxiosResponse<FileResponse[], any>> {
        return this.request.get(`search?term=${data}`)
    }

    public last (): Promise<AxiosResponse<any, any>> {
        return this.request.get(`search/last`);
    }

    public generatePdf (data: string): Promise<AxiosResponse<any, any>> {
        return this.request.get(`search/pdf?term=${data}`);
    }
}

export default SearchService;