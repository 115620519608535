import { AxiosResponse } from 'axios';
import { HttpClient } from '../components/infra/httpClient.component';

interface LoginRequest {
    login: string,
}

interface LogoutRequest {
    login: string,
    token: string
}

export class LoginService {
    private request: HttpClient;

    constructor(){
        this.request = new HttpClient();
    }

    public async doLogin(data: LoginRequest): Promise<AxiosResponse<any, any>> {
        return this.request.post(`login`, data);
    }

    public async healthCheck(): Promise<AxiosResponse<any, any>> {
        return this.request.get(`loginHealthCheck`);
    }

    public async logout(data: LogoutRequest): Promise<AxiosResponse<any, any>> {
        return this.request.post(`login/logout`, data);
    }
}


export default LoginService;