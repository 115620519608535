import React, { useEffect, useState } from 'react'
import { FileResponse, FileSource } from '../interfaces/FileResponse'
import pdfImg from "../../assets/img/pdf.png";
import { StringHelper } from '../infra/stringHelper.component';
import PdbDropbox from '../pbDropbox/dropbox.component';
import style from './sheet-item.module.css';
import mainStyles from '../../index.module.css'
import FileHelper from '../../helpers/filehelper.helper';
import MovementButton, { IMovementButton, MovementType } from './movement-button.component';

export interface ImageItemProps {
    item: FileResponse,
    addButton?: IAddButton,
    upButton?: IMovementButtonProps,
    firstButton?: IMovementButtonProps,
    downButton?: IMovementButtonProps,
    lastButton?: IMovementButtonProps,
    removeButton?: IMovementButtonProps,
}

export interface IAddButton {
    position: AddButtonPosition,
    caption?: string,
    onClick?: (item: FileResponse) => Promise<any>
}

export enum AddButtonPosition {
    High,
    Low
}

export interface IMovementButtonProps {
    visible: boolean
    onClick?: (item: FileResponse) => Promise<any>
}

export default function SheetItem(props: ImageItemProps) {
    const [srcValue, setSrcValue] = useState<string>();
    const [aditionalInfo, setAditionalInfo] = useState<string>();
    const [extension, setExtension] = useState<string>();
    const [addButtonCaption, setAddButtonCaption] = useState<string>('Adicionar ao repertório ➕');
    const [blobContent, setBlobContent] = useState<Blob>();

    const drop = new PdbDropbox();

    useEffect(() => {
        loadCurrent()
    }, [props])

    const loadCurrent = async () => {
        if (srcValue) return;

        if (props.addButton) {
            const addButton = props.addButton!;
            if (addButton.caption)
                await setAddButtonCaption(addButton.caption!);
        }

        switch (props.item.source) {
            case FileSource.Dropbox:
                const downloadedContent: any = await drop.GetFromServer(props.item.serverPath)
                await setExtension(props.item.extension);

                if (props.item.extension !== 'pdf') {

                    const image = await StringHelper.blobToBase64Image(downloadedContent, props.item.extension);

                    await setSrcValue(image);
                }
                else {
                    await setBlobContent(downloadedContent);

                    if (!navigator.pdfViewerEnabled) {
                        await setSrcValue(pdfImg);
                    }
                    else {
                        const pdf = await StringHelper.blobToBase64Pdf(downloadedContent);
                        await setSrcValue(pdf)
                    }

                    await setAditionalInfo(props.item.address);
                }

                break;
            default:
                await setSrcValue(props.item.address);
                break;
        }
    }

    const onAddRepertoireClick = async () => {
        if (props.addButton && props.addButton.onClick)
            await props.addButton!.onClick(props.item);
    }

    const showImage = () => srcValue && (extension !== 'pdf' || !navigator.pdfViewerEnabled);
    const showPdf = () => srcValue && extension === 'pdf' && navigator.pdfViewerEnabled;

    const download = () => FileHelper.TryDownload(props.item.address, blobContent!);

    const AddRepetoire = <div className={style.actionMenu}>
        <div className={mainStyles.tooltip}>
            <div className={mainStyles.button} onClick={onAddRepertoireClick}>{addButtonCaption}</div>
            <span className={mainStyles.tooltiptext}>
                {'Adicionar ao repertório'}
            </span>
        </div>
    </div>

    return <div style={{
        border: '2px solid',
        borderRadius: '5px'
    }}>
        {props.addButton && props.addButton.position === AddButtonPosition.High && AddRepetoire}
        <div>
            {showImage() && <div onClick={download} className={style.divImage}>
                <img className={style.image} src={srcValue} />
            </div>}
            {showPdf() && <object className={style.pdf} data={srcValue} type="application/pdf" />}
            {aditionalInfo && <>
                <div className={style.pdfLabel}><label>{aditionalInfo}</label></div>
            </>}
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
            }}>
                {props.upButton && props.upButton.visible && <MovementButton 
                    buttonType={MovementType.Up}
                    onClick={props.upButton.onClick!}
                    current={props.item}
                 />}
                 {props.firstButton && props.firstButton.visible && <MovementButton 
                    buttonType={MovementType.First}
                    onClick={props.firstButton.onClick!}
                    current={props.item}
                 />}
                 {props.removeButton && <MovementButton 
                    buttonType={MovementType.Remove}
                    onClick={props.removeButton.onClick!}
                    current={props.item}
                 />}                 
                 {props.downButton && props.downButton.visible && <MovementButton 
                    buttonType={MovementType.Down}
                    onClick={props.downButton.onClick!}
                    current={props.item}
                 />}
                 {props.lastButton && props.lastButton.visible && <MovementButton 
                    buttonType={MovementType.Last}
                    onClick={props.lastButton.onClick!}
                    current={props.item}
                 />}
            </div>
        </div>

        {props.addButton && props.addButton.position === AddButtonPosition.Low && AddRepetoire}
    </div>
}